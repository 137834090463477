<template>
  <div id="app_nav" v-show="visible">
    <div class="app_link" exact>
      <router-link to="/home">
        <img class="img"
             :src="'/home' === $route.path ? tabBarImgArr[0].selected : tabBarImgArr[0].normal"
             alt="" />

        <p>首页</p>
      </router-link>
    </div>
    <div class="app_link">
      <router-link to="/recording">
        <img class="img"
             :src="'/recording' === $route.path ? tabBarImgArr[1].selected : tabBarImgArr[1].normal"
             alt="" />

        <p>邀请记录</p>
      </router-link>
    </div>

    <div class="app_link">
      <router-link to="/shareit">
        <img class="img"
             :src="'/shareit' === $route.path ? tabBarImgArr[2].selected : tabBarImgArr[2].normal"
             alt="" />

        <p>推广邀请</p>
      </router-link>
    </div>
    <div class="app_link">
      <router-link to="/user">
        <img class="img"
             :src="'/user' === $route.path ? tabBarImgArr[3].selected : tabBarImgArr[3].normal"
             alt="" />

        <p>我的</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      tabBarImgArr: [
        //图片切换
        {
          normal: require("../../assets/index.png"),
          selected: require("../../assets/index1.png"),
        },
        {
          normal: require("../../assets/recording.png"),
          selected: require("../../assets/recording1.png"),
        },
        {
          normal: require("../../assets/guide.png"),
          selected: require("../../assets/guide1.png"),
        },
        {
          normal: require("../../assets/user.png"),
          selected: require("../../assets/user1.png"),
        },
      ],
    };
  },
  methods: {},
  watch: {
    $route: {
      handler(n) {
        this.visible = !n.meta.flag;
      },
      immediate: true,
    },
  },
};
</script>

<style>
a{
  text-decoration:none;
}
#app_nav {
  left: 0;
  width: 100%;
  z-index: 100;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding-top: .28rem;
  padding-bottom: .4rem;
}
.app_link p{
  margin: 0;
}
#app_nav .app_link {
  flex: 1;
  text-align: center;
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f44c06;
  font-size: .24rem;
}
#app_nav .app_link .img {
  width: .49rem;
  height: .49rem;
}
#app_nav .app_link .yuan {
  display: block;
  width: 1.12rem;
  height: 1.12rem;
  background-size: 100%;
  position: fixed;
  bottom: 0.57rem;
  margin-left: 0.15rem;
}
#app_nav .app_link .yuan .bei {
  margin-top: 0.15rem;
  width: 0.44rem;
  height: 0.44rem;
}
#app_nav .app_link .yuan .diao {
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
#app_nav .app_link p:hover {
  color: #f44c06;
}
#app_nav .app_link p {
  color: #616b80;
}
</style>