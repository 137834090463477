import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/login/index.vue"),
    meta: {
      title: "登录",
      flag:true
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/index.vue"),
    meta: {
      title: "首页",

    },
  },
  {
    path: "/recording",
    name: "recording",
    component: () => import("../views/recording/index.vue"),
    meta: {
      title: "邀请记录",
    },
  },
  {
    path: "/shareit",
    name: "shareit",
    component: () => import("../views/shareit/index.vue"),
    meta: {
      title: "邀请指南",
      flag:true
    },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/index.vue"),
    meta: {
      title: "我的",
    },
  },
  {
    path: "/invitemsg",
    name: "invitemsg",
    component: () => import("../views/invitemsg/index.vue"),
    meta: {
      title: "邀请有礼详情",
      flag: true
    },
  },
  {
    path: "/systeminformation",
    name: "systeminformation",
    component: () => import("../views/systeminformation/index.vue"),
    meta: {
      title: "系统消息",
      flag: true
    },
  },
  {
    path: "/myaccount",
    name: "myaccount",
    component: () => import("../views/myaccount/index.vue"),
    meta: {
      title: "我的账户",
      flag: true
    },
  },
  {
    path: "/userinformation",
    name: "userinformation",
    component: () => import("../views/userinformation/index.vue"),
    meta: {
      title: "用户信息",
      flag: true
    },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../views/withdraw/index.vue"),
    meta: {
      title: "余额提现",
      flag: true
    },
  },
  {
    path: "/withdrawrecording",
    name: "withdrawrecording",
    component: () => import("../views/withdrawrecording/index.vue"),
    meta: {
      title: "提现记录",
      flag: true
    },
  },
  {
    path: "/incomerecording",
    name: "incomerecording",
    component: () => import("../views/incomerecording/index.vue"),
    meta: {
      title: "收益明细",
      flag: true
    },
  },
  {
    path: "/accountsetting",
    name: "accountsetting",
    component: () => import("../views/accountsetting/index.vue"),
    meta: {
      title: "账户设置",
      flag: true
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
    meta: {
      title: "登录",
      flag: true
    },
  },
  {
    path: "/serviceprotocol",
    name: "serviceprotocol",
    component: () => import("../views/serviceprotocol/index.vue"),
    meta: {
      title: "服务协议",
      flag: true
    },
  },
  {
    path: "/authorizationprotocol",
    name: "authorizationprotocol",
    component: () => import("../views/authorizationprotocol/index.vue"),
    meta: {
      title: "授权声明",
      flag: true
    },
  },
  {
    path: "/withdrawsuccess",
    name: "withdrawsuccess",
    component: () => import("../views/withdrawsuccess/index.vue"),
    meta: {
      title: "提现成功",
      flag: true
    },
  },
  {
    path: "/problem",
    name: "problem",
    component: () => import("../views/problem/index.vue"),
    meta: {
      title: "常见问题",
      flag: true
    },
  },
  {
    path: "/sharepage",
    name: "sharepage",
    component: () => import("../views/sharepage/index.vue"),
    meta: {
      title: "分享详情",
      flag: true
    },
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("../views/protocol/index.vue"),
    meta: {
      title: "协议",
      flag: true
    },
  },
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})
router.afterEach(route => {
  /**分享进入 */
  if (route.query.urlsharees != undefined && route.query.isshare == 1) {
    if (!sessionStorage.getItem("url")) {
      sessionStorage.setItem("url", route.query.urlsharees);
    }
    sessionStorage.setItem("sharemsg", JSON.stringify(route.query));
    if(route.query.tourlmsg){
      window.location = route.query.tourlmsg;
    }else{
      window.location = route.query.urlsharees;
    }
  } else {
    if (!sessionStorage.getItem("url")) {
      sessionStorage.setItem("url", route.path);
    }
  }
})


export default router;