<!--
 * @Author: your name
 * @Date: 2020-12-24 15:25:53
 * @LastEditTime: 2021-06-01 17:33:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \two\src\App.vue
-->
<template>
  <div id="app">
    <router-view />
    <tabble></tabble>
    <!-- <div class="kong"></div> -->
    <!-- <van-popup v-model="show">
      <div class="dclss">
        <img class="theclassimg" src="./assets/partner.jpg" />
        <button @click="confirm" id="topartner">去申请</button>
      </div>
    </van-popup> -->
    <allmask ref="allmaskid"></allmask>
  </div>
</template>

<script>
import tabble from "./components/tabble/index";
import router from "./router";
import allmask from "./components/allmask/index";
import PubSub from "pubsub-js";
export default {
  name: "App",
  components: {
    tabble,
    allmask,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    router.beforeEach((to, from, next) => {
      // if(to.path=="/shareit"){
      //   window.location = "/shareit";
      //   return;
      // }
      next();
    });
    router.afterEach((to) => {
      console.log(to.fullPath);
      if (
        // to.fullPath == "/" ||
        to.fullPath == "/home" ||
        to.fullPath == "/recording" ||
        to.fullPath == "/user"
      ) {
        this.selectmsg1();
      }
    });
  },
  methods: {
    selectmsg1() {
      this.$http
        .post("/firm/v1/Channel/see_status", {
          reqType: "userinfo",
        })
        .then((res) => {
          this.state = JSON.parse(res.data).data.is_channel;
          if (this.state == 1) {
            PubSub.publish("okren", "");
          } else if (this.state == 0) {
            document.body.className = "bodyclass";
            this.$refs.allmaskid.noren();
          } else if (this.state == 2) {
            document.body.className = "bodyclass";
            this.$refs.allmaskid.loadren();
          } else {
          }
        });
    },
  },
  destroyed(){
    
  }
};
</script>

<style>
body,#app {
  height: 100%;
}
html{
  min-height: 100%;
  background-color: #f5f7fa;
}
.bodyclass {
  position: fixed;
  width: 100%;
}
.kong {
  height: 1.8rem;
}
/*
  遮罩层
*/
.msk {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#topartner {
  position: absolute;
  background-color: #ffffff;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.59rem;
  font-size: 0.36rem;
  line-height: 0.32rem;
  color: #07b4f6;
  border: none;
  padding: 0.15rem 0;
  border-radius: 1rem;
  font-weight: 500;
  width: 4.34rem;
}
.dclss {
  width: 6.86rem;
  height: 9.97rem;
  position: relative;
  overflow: hidden;
}
.theclassimg {
  width: 100%;
  height: 100%;
}
.imgdivclass {
  text-align: center;
  margin-top: 0.54rem;
}
</style>
