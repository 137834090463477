import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './App.vue'
import router from './router'
import axios from "axios"
import http from '../util/httpes'

Vue.use(Vant);

Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.prototype.$axios = axios;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
