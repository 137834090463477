<!--
 * @Author: your name
 * @Date: 2021-01-11 18:50:05
 * @LastEditTime: 2021-01-14 17:42:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \two\src\components\allmask\index.vue
-->
<template>
  <div class="allmsgclass">
    <div
      v-if="show == true || show2 == true || show3 == true"
      class="msk"
    ></div>
    <div v-if="show == true" class="themsg">
      <img src="../../assets/partner.jpg" />
      <div class="fuxuan">
        <div class="fu2">
          <van-checkbox v-model="checked"
            >我已阅读并同意<span @click.stop="toxie" class="xyclass"
              >《事业合伙人协议》</span
            >
          </van-checkbox>
        </div>
      </div>

      <button @click="confirm" id="topartner">去申请</button>
    </div>
    <div v-if="show2 == true" class="theshenheclass">
      <div class="successimg"><img src="../../assets/success.png" /></div>
      <div class="msg1">你的申请已成功提交</div>
      <div class="msg2">请联系客服，咨询审核进度</div>
      <div class="qrimg"><img src="../../assets/luobo2.png" /></div>
    </div>
    <div v-if="show3 == true" class="theshenheclass">
      <div class="successimg"><img src="../../assets/success.png" /></div>
      <div class="msg1">你的申请已成功提交</div>
      <div class="msg2">请联系客服，咨询审核进度</div>
      <div class="qrimg"><img src="../../assets/luobo2.png" /></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      show2: false,
      show3: false,
      checked: false,
    };
  },
  mounted() {},

  methods: {
    toxie() {
      this.show2 = false;
      this.show3 = false;
      this.show = false;
      document.body.className = "";
      this.$router.push({
        path: "protocol",
        query: {},
      });
    },
    loadren() {
      this.show3 = true;
    },
    noren() {
      this.show = true;
    },
    //点击去申请认证
    confirm() {
      if (!this.checked) {
        this.$toast("请勾选协议！");
        return;
      }
      document.body.className = "";

      this.$http
        .post("/firm/v1/Channel/apply_channel", {
          reqType: "userinfo",
        })
        .then((res) => {
          this.show = false;
          this.show2 = true;
          this.$toast("已提交");
        });
    },
  },
};
</script>
<style scoped>
.xyclass {
  color: #07b4f6;
}
.fu2 {
  background-color: #ffffff;
  display: inline-block;
  padding: 0 0.1rem;
  border-radius: 0.2rem;
}
.fuxuan {
  position: absolute;
  bottom: 0.1rem;
  font-size: 0.2rem;
  width: 100%;
  text-align: center;
}
.allmsgclass >>> .van-checkbox__label {
  display: block;
  white-space: normal;
}
.msg1 {
  font-size: 0.38rem;
  line-height: 0.4rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.12rem;
}
.msg2 {
  font-size: 0.28rem;
  line-height: 0.4rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.11rem;
}
.successimg img {
  width: 1.11rem;
  height: 1.11rem;
}
.successimg {
  text-align: center;
  margin-top: 0.62rem;
  margin-bottom: 0.36rem;
}
.qrimg {
  text-align: center;
}
.qrimg img {
  width: 2.82rem;
  height: 2.82rem;
}
.theshenheclass {
  position: fixed;
  width: 5.44rem;
  height: 6.55rem;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 0.3rem;
}
.themsg {
  position: fixed;
  width: 6.86rem;
  height: 10.97rem;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}
.themsg img {
  width: 100%;
  height: 100%;
}
</style>
