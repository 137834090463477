/*
 * @Author: your name
 * @Date: 2020-12-29 16:24:10
 * @LastEditTime: 2021-01-12 10:54:16
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \two\util\httpes.js
 */
import instance from "./http";
import domain from "./base";
import QS from "qs";
import { Dialog } from "vant";

const get = (url, params) => {
    return new Promise((resolve, reject) => {
        instance
            .get(`${domain.formal}${url}`, {
                params: params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
};

const post = (url, params) => {
    let parames = {
        accessToken:
        sessionStorage.getItem("accessToken") != undefined
                ? sessionStorage.getItem("accessToken")
                : "",
        user_id:
        sessionStorage.getItem("userId") != undefined
                ? sessionStorage.getItem("userId")
                : "",
        token:
        sessionStorage.getItem("token") != undefined
                ? sessionStorage.getItem("token")
                : "",
        requestParams: params,
    };
    return new Promise((resolve, reject) => {
        instance
            .post(`${domain.formal}${url}`, QS.stringify(parames))
            .then((res) => {
                if(JSON.parse(res.data).code!="0"&&JSON.parse(res.data).code!=8073){
                    Dialog({ message: JSON.parse(res.data).msg });
                    return;
                }
                resolve(res);
            })
            .catch((err) => {
                 
                // this.$toast(JSON.parse(err.data).msg)
                //  
                reject(err);
            });
    });
};

export default {
    get,
    post,
};
